import logo from './Logo2White.svg';
import './App.css';
import Button from '@mui/material/Button';
import React, { useEffect } from "react";
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import IconButton from '@mui/material/IconButton';
import EmailIcon from '@mui/icons-material/Email';
import SpinningLogo from './SpinningLogo';
import OpenseaLogo from './icons/OpenseaLogo.svg';
import { Icon } from '@mui/material';


import "./fonts/Agrandir-GrandHeavy.otf";


function App() {
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);


  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <SpinningLogo></SpinningLogo>

        <div style={{fontSize: 40, paddingTop: 30, paddingBottom: 0}}>
          HISTORY IS HISTORY NFT FUND      
          {/* <p>
          Historical NFT Fund
          </p> */}
        </div>
        <div style={{fontSize: 25, paddingTop:0, paddingBottom: 25}}>
          Investing in Historically Significant NFTs from as far back as 2011.
        </div>
        {/* <Button sx={{borderRadius: 0, border: "1px solid", width: "18em",height: "3em", color: "#000", ":hover":{backgroundColor: "#F5F5F5", borderColor: "#000"}}} variant="outlined" style={{textTransform: 'lowercase', backgroundColor: "#fffff", fontFamily: "Crimson Text"}} target="_blank" href="https://forms.monday.com/forms/1e508f2d7781c8eebe37144a12c6912c?r=use1" >request information</Button> */}
        <div style={{paddingTop: 0}}>

          <IconButton disableFocusRipple disableRipple style={{ backgroundColor: "transparent", color: "#ffffff" }} aria-label="delete" onClick={() => window.open('https://twitter.com/hihnftfund')}>
            <TwitterIcon fontSize="large" color='#ffffff' />
          </IconButton>

          <IconButton disableFocusRipple disableRipple style={{ backgroundColor: "transparent", color: "#ffffff" }} aria-label="delete" onClick={() => window.open('https://www.linkedin.com/company/history-is-history/')}>
            <LinkedInIcon fontSize="large" color='#ffffff' />
          </IconButton>

          <IconButton disableFocusRipple disableRipple style={{ backgroundColor: "transparent", color: "#ffffff" }} aria-label="delete" onClick={() => window.open('mailto:contact@historyishistory.io')}>
            <EmailIcon fontSize="large" color='#ffffff' />
          </IconButton>

          <IconButton disableFocusRipple disableRipple style={{ backgroundColor: "transparent", color: "#ffffff" }} aria-label="delete" onClick={() => window.open('https://opensea.io/HistoryIsHistory')}>
            {/* <EmailIcon fontSize="large" color='#ffffff' /> */}
            <img src={OpenseaLogo} height={25} width={25}/>
          </IconButton>
        </div>

      </header>
    </div>
  );
}

export default App;
